import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
// import parse from 'html-react-parser';
import Figure from './figure'
import Slideshow from './slideshow'

import typography from '../typography.module.css'

const serializers = {
  types: {
    block (props) {
      switch (props.node.style) {
        case 'h1':
          return <h1>{props.children}</h1>

        case 'h2':
          return <h2>{props.children}</h2>

        case 'h3':
          return <h3>{props.children}</h3>

        case 'h4':
          return <h4>{props.children}</h4>

        case 'blockquote':
          return <blockquote>{props.children}</blockquote>

        default:
          if (props.children.length === 1 && typeof props.children[0] === 'string' && props.children[0].trim() === '') {
            return '';
          } else {
            return <p className={typography.paragraph}>{props.children}</p>
          }
      }
    },
    figure (props) {
      return <Figure {...props.node} />
    },
    slideshow (props) {
      return <Slideshow {...props.node} />
    },
    embedCode (props) {
      if (props.node.code) {
        // return parse(props.node.code);
        return <div dangerouslySetInnerHTML={{__html: props.node.code}} />
      }
      return <></>
    }
  },
  marks: {
    link: ({mark, children}) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark
      return blank ?
        <a href={href} target="_blank" rel="noopener">{children}</a>
        : <a href={href}>{children}</a>
    }
  }
}

const BlockContent = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />

export default BlockContent
