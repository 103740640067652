import React from 'react'
import Container from '../container'
import Icon from '../icons';

import * as styles from './authorBar.module.css';

const AuthorBar = ({authors}) => {
    // console.log(authors);
    return (
        <div className={styles.authorBg}>
            <Container size="large">
                <div className={styles.authorsWrap}>
                    {authors && authors.map((author, authorIndex) => (
                        <div className={styles.authorWrap} key={authorIndex}>
                            <div className={styles.profilePicture}>
                                <img src={`${author.profileColor.asset.url}?h=320&w=320&fit=crop&crop=center`} alt={author.name} />
                            </div>

                            <div className={styles.profileInfo}>
                                <div className={styles.overline}>Author</div>
                                <div className={styles.h5}>{author.name}</div>
                                <div className={styles.bio}>{author.metaDescription}</div>
                                <div className={styles.socials}>
                                    {author.socialTwitter && (<a href={author.socialTwitter} className={styles.socialIcon}><Icon symbol="twitter" /></a>)}
                                    {author.socialInstagram && (<a href={author.socialInstagram} className={styles.socialIcon}><Icon symbol="instagram" /></a>)}
                                    {author.socialLinkedin && (<a href={author.socialLinkedin} className={styles.socialIcon}><Icon symbol="linkedin" /></a>)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    )
}

export default AuthorBar;