import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
// import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hub/hero/hero'
// import CardSlider from '../components/hub/cardSlider/cardSlider'
import SubscribeBar from '../components/subscribeBar/subscribeBar'
import BlockContent from '../components/block-content/v2'
import AuthorBar from '../components/authorBar/authorBar'
import ShareLinks from '../components/shareLinks/shareLinks'

import * as styles from '../components/globals-v2.module.css'

export const query = graphql`
  query WrittenTemplateQuery($id: String!) {
    written: sanityWritten(id: { eq: $id }) {
        _type
        tags {
            title
            slug {
                current
            }
        }
        title
        blurb
        slug {
            current
        }
        featureImage {
            asset {
                url
                _id
            }
        }
        backgroundImage {
            asset {
                _id
                url
            }
        }
        author {
            name
            slug {
                current
            }
            team
            socialTwitter
            socialMedium
            socialLinkedin
            socialInstagram
            profileColor {
                asset {
                    url
                }
            }
            metaDescription
        }
        publishedAt(formatString: "MMMM D, YYYY")
        _rawContent
        metaTitle
        metaDescription
        metaKeywords
        metaImageFB {
          asset {
              _id
              url
          }
        }
        metaImageTW {
          asset {
              _id
              url
          }
        }
    }
  }
`

const WrittenTemplate = props => {
  const { data, errors } = props
  const written = data && data.written;
  
  return (
    <Layout hideContactBar={true} hideAnnouncements={true} logoOverrideUrl="/start/" darkMode={true} version="2">
        {/* {errors && <SEO title='GraphQL Error' />}
        {landing && <SEO title={landing.title || 'Untitled'} />}

        {errors && (
            <Container>
            <GraphQLErrorList errors={errors} />
            </Container>
        )} */}

        <SEO title={written.metaTitle || written.title} description={written.metaDescription} keywords={written.metaKeywords} imageFB={written.metaImageFB} imageTW={written.metaImageTW} />

        <Hero type="written" record={written} />

        <div className={styles.backgroundControl}>
            <Container size="large">
                <div className={styles.articleFormat}>
                    <div className={styles.articleShare}><ShareLinks title={written.title} /></div>
                    <BlockContent blocks={written._rawContent} />
                </div>

                <AuthorBar authors={written.author} />
            </Container>
        </div>

        {/* <div className={styles.backgroundControl}>
            <Container size="large" class={styles.sectionPad}>
                <section className={`videos`}>
                    <h6 style={{margin: "0 0 60px", textAlign: "center"}}>YOU MIGHT LIKE</h6>
                    <p>To be defined</p>
                    {/* <CardSlider type="visual" data={videoPosts} /> *}
                </section>
            </Container>
        </div> */}

        <div className={styles.backgroundControlAlt}>
            <Container size="large">
                <SubscribeBar />
            </Container>
        </div>
    </Layout>
  )
}

export default WrittenTemplate
